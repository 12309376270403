import React from "react";
import Post from "../components/post/post";
import Header from "../components/header/header.js";

import { StaticQuery, graphql } from "gatsby";

const query = graphql`
  query {
    allStrapiConnectAtYouWebsiteBlogs(sort: { order: DESC, fields: created_at }) {
      edges {
        node {
          strapiId
          title
          slug
          body
          excerpt
          chosen_date(formatString:"DD.MM.YYYY")
          image {
            publicURL
          }
        }
      }
    }
  }
`;

const Blog = () => {

  return (
    <>
      <Header title="Blog &amp; News" />
      <div className="container">
        <StaticQuery
          query={query}
          render={(data) =>
            data.allStrapiConnectAtYouWebsiteBlogs.edges.map((post, index) => {
              const { title, body, slug, image, excerpt, chosen_date } = post.node;

              return (
                <Post
                  title={title}
                  index={index}
                  author={"Diego"}                  
                  description={body}
                  key={slug}
                  path={slug}
                  image={image.publicURL}
                  excerpt={excerpt}
                  chosen_date={chosen_date}
                />
              );
            })
          }
        />
      </div>
    </>
  );
};

export default Blog;

import React from "react";
import ReadMore from "../../components/readMore/readMore.js";
import "./style.scss";

const Post = ({
  title,
  index,
  author,
  date,
  description,
  path,
  image,
  excerpt,
  chosen_date,
}) => (
  <div className="post">
    {index % 2 === 0 ? (
      <div className="columns">
        <div className="column is-3 ">
          <div className="post-image">
            <img src={image} alt={title} />
          </div>
        </div>
        <div className="column is-6 is-offset-3">
          <b
            className="date"
            dangerouslySetInnerHTML={{ __html: chosen_date }}
          />
          <br />
          <br />
          <span className="azure bold">{title}</span>
          <br />
          <br />
          <p
            dangerouslySetInnerHTML={{ __html: excerpt }}
            className="post-description"
          />
          <br />
          <ReadMore to={path} text="Leggi di più" />
        </div>
      </div>
    ) : (
      <div className="columns invert">
        <div className="column is-6">
          <b
            className="date"
            dangerouslySetInnerHTML={{ __html: chosen_date }}
          />
          <br />
          <br />
          <span className="azure bold">{title}</span>
          <br />
          <br />
          <p
            dangerouslySetInnerHTML={{ __html: excerpt }}
            className="post-description"
          />
          <br />
          <ReadMore to={path} text="Leggi di più" />
        </div>
        <div className="column is-3 is-offset-3">
          <div className="post-image">
            <img src={image} alt={title} />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default Post;
